import "./App.css";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import theme from "./theme";
import NavBar from "./navbar";
import TextMobileStepper from "./createcase";
import Container from "@mui/material/Container";
import List from "./listorder";
import LogIn from "./login";
import AddProduct from "./addproduct";
import CasePage from "./casepage";
import ClientPage from "./clientpage";
import firebase from "./firebase";
import "firebase/auth";

function App() {
  const [loged, setLoged] = useState(false);
  const [caseId, setCaseId] = useState(false);
  const [userId, setuserID] = useState("0");
  const [user, setUser] = useState(null);

  /**
   * Authentication guard for routes
   * @param {PropTypes.node} children children element/node
   */
  const AuthGuard = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
      firebase.auth().onAuthStateChanged((user) => {
        if (
          !user &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/track"
        ) {
          setLoged(false);
          navigate("/login");
        } else if (!user) {
          setLoged(false);
        } else {
          setUser(user);
          setLoged(true);
        }
      });
    }, [navigate]);

    return children;
  };

  AuthGuard.propTypes = {
    children: PropTypes.node,
  };

  const signOut = () => {
    firebase.auth().signOut();
  };

  const logInCall = (user) => {
    setLoged(true);
  };

  const setCaseIdfunc = (caseId) => {
    setCaseId(caseId);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="ms">
        <BrowserRouter>
          <NavBar logged={loged} />
          <AuthGuard>
            <Routes>
              <Route
                path="/"
                element={<TextMobileStepper user={user} />}
              ></Route>
              <Route
                path="/list"
                element={<List logged={loged} setid={setCaseIdfunc} />}
              />

              <Route
                path="/addproduct"
                element={<AddProduct logged={loged} />}
              />
              <Route
                path="/case"
                element={
                  <CasePage logged={loged} caseId={caseId} user={user} />
                }
              />
              <Route path="/track" element={<ClientPage logged={loged} />} />
              <Route path="/login" element={<LogIn login={logInCall} />} />
            </Routes>
          </AuthGuard>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
