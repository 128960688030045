import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import DoneIcon from "@mui/icons-material/Done";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "moment/locale/sv";

moment.locale("sv");
export default function CustomizedTimeline(props) {
  React.useEffect(() => {
    moment.locale("sv");
  }, []);
  function isOdd(num) {
    return num % 2;
  }
  return (
    <Timeline position="alternate">
      {props.statuses.map((status, index) => (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align={isOdd(index) === 1 ? "right" : "left"}
            variant="body2"
            color="text.secondary"
          >
            {moment.unix(status.time).fromNow()}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              color={
                index === props.statuses.length - 1 ? "primary" : "secondary"
              }
            >
              <DoneIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {status.name}
            </Typography>
            <Typography>{status.description}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
