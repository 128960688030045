import React, { useEffect, useState } from "react";
import firebase from "./firebase";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/styles";
import ChatBox from "./chatBox";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import SendMessage from "./sendmessage";
import LinearProgress from "@mui/material/LinearProgress";
import { Checkbox } from "@mui/material";
import RichTextEditor from "./RichTextEditor";
var db = firebase.firestore();

export default function ChatBlock(props) {
  let [message, setMessage] = useState("");
  let [messages, setMessages] = useState([]);
  const [internalMessages, setInternalMessages] = useState([]);
  const [prog, setProg] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [imageLink, setImageLink] = useState("");
  const [notifyCheck, setNotifyCheck] = useState(true);
  const [richTextContent, setRichTextContent] = useState("");
  const [shouldClearEditor, setShouldClearEditor] = useState(false);

  const handleContentChange = (htmlContent) => {
    setRichTextContent(htmlContent);
  };

  const handleClearEditorClick = () => {
    setShouldClearEditor((prevState) => !prevState);
  };

  const Input = styled("input")({ display: "none" });

  let caseData = props.caseData;
  let caseId = props.caseData.ticketId;

  function updateMessages() {
    let messagesArr = [];
    let interMessagesArr = [];
    db.collection("tickets")
      .doc(caseId.toString())
      .collection("internalMessages")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          interMessagesArr.push(doc.data());
        });
      })
      .then(() => {
        setInternalMessages(interMessagesArr);
      });
    db.collection("tickets")
      .doc(caseId.toString())
      .collection("messages")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //
          messagesArr.push(doc.data());
        });
      })
      .then(() => {
        setMessages(messagesArr);
      });
  }

  function send() {
    SendMessage(
      message,
      caseData.mobil,
      caseData.ticketId,
      props.view,
      caseData.token,
      imageLink,
      caseData.countryCod,
      props.internal,
      caseData.email,
      caseData.name,
      notifyCheck,
      richTextContent
    ).then((res) => {
      updateMessages();
      setMessage("");
      setImageLink("");
      handleClearEditorClick();
      setProg(false);
    });
  }

  useEffect(() => {
    let interMessagesArr = [];
    let messagesArr = [];
    if (props.logged) {
      db.collection("tickets")
        .doc(caseId.toString())
        .collection("internalMessages")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            interMessagesArr.push(doc.data());
          });
        })
        .then(() => {
          setInternalMessages(interMessagesArr);
        });
    }
    db.collection("tickets")
      .doc(caseId.toString())
      .collection("messages")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          messagesArr.push(doc.data());
        });
      })
      .then(() => {
        setMessages(messagesArr);
      });
  }, []);

  const handelFile = async (e) => {
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child("images/" + file.name).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume Get task
        // progress, including the number of bytes uploaded and the total number of
        // bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProg(progress);

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
          default:
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete For instance, get the download URL:
        // https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setImageLink(downloadURL);
          setOpen(true);
        });
      }
    );
  };

  return (
    <>
      <Box mt={2}>
        {messages.map((mes) => (
          <ChatBox
            internal={mes.internal}
            key={mes.time}
            time={mes.time}
            align={mes.sender === "1" ? "flex-start" : "flex-end"}
            text={mes.message}
            img={mes.image}
            content={mes.content}
          />
        ))}
      </Box>
      {props.logged && (
        <Box>
          {internalMessages.map((imes) => (
            <ChatBox
              internal={imes.internal}
              key={imes.time}
              time={imes.time}
              align={imes.sender === "1" ? "flex-start" : "flex-end"}
              text={imes.message}
              img={imes.image}
              content={imes.content}
            />
          ))}
        </Box>
      )}
      <Box mb={2} component="form">
        <Typography variant="h6">
          Skriv till oss här om du behöver hjälp angående ditt ärende
        </Typography>
        <RichTextEditor
          onContentChange={handleContentChange}
          clearEditor={shouldClearEditor}
        />
        <Box mt={1} flexWrap="wrap" justifyContent="end" display="flex">
          {props.view === "0" && (
            <Box display="flex" flexDirection="row">
              <Box m={1}>
                <Typography alignSelf="center">Notify</Typography>
                <Checkbox
                  checked={notifyCheck}
                  onChange={(e) => {
                    setNotifyCheck(e.target.checked);
                  }}
                />
              </Box>
              <Box m={1}>
                <Typography alignSelf="center">Internal</Typography>
                <Checkbox
                  checked={props.internal}
                  onChange={(e) => {
                    props.changeInternal(e.target.checked);
                  }}
                />
              </Box>
            </Box>
          )}
          <Box>
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={handelFile}
              />
              <Button
                style={{ marginRight: "8px" }}
                component="span"
                variant="contained"
                endIcon={<FileUploadIcon />}
                color={"secondary"}
              />
            </label>
            {prog && (
              <div>
                <Progresbar
                  color={"secondary"}
                  variant="determinate"
                  value={prog}
                />
                <span>
                  {prog}
                  {" %"}
                </span>
              </div>
            )}
            {imageLink !== "" && (
              <img src={imageLink} alt={"asd"} width="150" />
            )}
          </Box>

          <Button variant="contained" endIcon={<SendIcon />} onClick={send}>
            Send
          </Button>
        </Box>
      </Box>
    </>
  );
}

const Progresbar = styled(LinearProgress)({ marginTop: "12px" });
