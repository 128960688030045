import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { FormControlLabel, Grid, Link } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { useNavigate, useSearchParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";

import { styled } from "@mui/styles";
import Stack from "@mui/material/Stack";
import firebase from "./firebase";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import "firebase/storage";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import uniqueString from "unique-string";
import moment from "moment";
import PriceShow from "./priceshow";

const axios = require("axios");

var db = firebase.firestore();

export default function VerticalLinearStepper(props) {
  const defaultValues = {
    name: "",
    email: "",
    adress: "",
    mobil: "",
    describtion: "",
    imageUrl: "",
  };

  const defaultValuesErrors = {
    name: false,
    nameHelper: "",
    email: false,
    adress: false,
    mobil: false,
    describtion: false,
    imageUrl: false,
  };
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let caseId = searchParams.get("id");

  const [formValues, setFormValues] = useState(defaultValues);
  const [formValuesError, setFormValuesError] = useState(defaultValuesErrors);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeButton, setActiveButton] = useState("");
  const [productsArray, setProductsArray] = useState([]);
  const [variationArr, setVariationArr] = useState([]);
  const [servicesArr, setServicesArr] = useState([]);
  const [activeProduct, setActiveProduct] = useState("");
  const [activeVariation, setActiveVariation] = useState("");
  const [activeService, setActiveService] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [ticketId, setTicketId] = useState(false);
  const [token, setToken] = useState("");
  const [countryCod, setCountrycod] = useState("46");
  const [print, setprint] = useState(false);
  const [value, setValue] = React.useState("Skicka");

  const [prog, setProg] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setCountrycod(event.target.value);
  };

  const Input = styled("input")({ display: "none" });

  useEffect(() => {
    let productsArr = [];
    db.collection("products")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          let data = doc.data();
          if (data.id === "annat" && !props.user) {
            return;
          }
          productsArr.push(doc.data());
        });
        let lastarr = productsArr.sort(
          (a, b) => parseFloat(a.rank) - parseFloat(b.rank)
        );
        setProductsArray(lastarr);
      });
  }, []);

  const sendSMS = (ticketid, nameE, emailE) => {
    //fel ssms track id
    //let smsNr = countryCod + mobil.replace(/^0+/, "");
    let smstosend = `Din förfrågan är mottagen nu. Ditt ärendenummer ${ticketid} För merinformation kan du följa händelseförloppet via länken `;
    axios.post(
      `https://helical-arcade-322422.ew.r.appspot.com/sendemail?sms=${smstosend}&ticket=${ticketid}&token=${token}&name=${nameE}&email=${emailE}`
    );
  };

  const printkvitto = () => {
    setprint(true);
    window.print();
  };

  const handel = (e) => {
    setActiveButton(e);
    let vararr = [];
    db.collection("products")
      .doc(e)
      .collection("variant")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          vararr.push(doc.id);
        });
      })
      .then(() => {
        setVariationArr(vararr);
      });
    setServicesArr([]);
    setActiveVariation("");
    setActiveService("");
  };

  const handelVariation = (variation) => {
    let serarr = [];
    setActiveVariation(variation);
    db.collection("products")
      .doc(activeButton)
      .collection("variant")
      .doc(variation)
      .collection("services")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          serarr.push(data);
        });
      })
      .then(() => {
        setServicesArr(serarr);
      });
    setActiveService("");
  };

  const validateForm = () => {
    if (formValues.name === "") {
      setFormValuesError({
        ...formValuesError,
        name: true,
        nameHelper: "Fyll i namn",
      });
      return true;
    }
    let regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(formValues.email) === false) {
      setFormValuesError({
        ...formValuesError,
        email: true,
        emailHelper: "Fyll i email",
      });
      return true;
    }
    if (formValues.mobil === "") {
      setFormValuesError({
        ...formValuesError,
        mobil: true,
        mobilHelper: "Fyll i mobilnummer",
      });
      return true;
    }
    if (formValues.describtion === "") {
      setFormValuesError({
        ...formValuesError,
        describtion: true,
        describtionHelper: "Fyll i describtion",
      });
      return true;
    }
    if (formValues.adress === "") {
      setFormValuesError({
        ...formValuesError,
        adress: true,
        adressHelper: "Fyll i Adress",
      });
      return true;
    }
  };

  const updateCase = () => {
    let costs = [];
    for (let index = 0; index < activeService.length; index++) {
      const element = activeService[index];
      const find = servicesArr.find((e) => e.id === element);

      if (find) {
        let obj = {
          item: element,
          price: parseInt(find.price),
          cost: parseInt(find.cost),
          priceId: find.stripPriceid || null,
          time: moment().unix(),
          paid: false,
          paidTime: moment().unix(),
        };
        costs.push(obj);
      }
    }
    db.collection("tickets")
      .doc(caseId)
      .update({
        costs: firebase.firestore.FieldValue.arrayUnion(...costs),
      })
      .then(() => {
        navigate(`/case?id`, { state: { caseId: caseId } });
      });
  };

  const handleNext = () => {
    if (activeStep === 1) {
      if (validateForm()) {
        return;
      }
      let maindIdRef = db.collection("tickets").doc("mainId");
      maindIdRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            let mainId = doc.data().mainTicketId;
            let date = Date.now();
            setTicketId(mainId + 1, date);
            setToken(uniqueString());
            let tokenvalue = uniqueString();
            setToken(tokenvalue);
            setFormValues({
              ...formValues,
              ticketId: mainId + 1,
              type: [activeButton, activeVariation, activeService.toString()],
              currentStatus: 1,
              status: [
                {
                  id: "1",
                  name: "Inväntar bekräftelset",
                  time: moment().unix(),
                  description: "Ärende Skapats",
                },
              ],
              token: tokenvalue,
              timeCreated: moment().unix(),
            });
            //sending the last stage

            let costs = [];
            for (let index = 0; index < activeService.length; index++) {
              const element = activeService[index];
              const find = servicesArr.find((e) => e.id === element);

              if (find) {
                let obj = {
                  item: element,
                  price: parseInt(find.price),
                  priceId: find.stripPriceid || null,
                  time: moment().unix(),
                  paid: false,
                  paidTime: moment().unix(),
                };
                costs.push(obj);
              }
            }
            db.collection("tickets")
              .doc((mainId + 1).toString())
              .set({
                ...formValues,
                ticketId: mainId + 1,
                type: [activeButton, activeVariation, activeService.toString()],
                variation: activeVariation,
                serivce: activeService,
                product: activeButton,
                currentStatus: 1,
                countryCod: countryCod,
                costs: costs,
                status: [
                  {
                    id: "1",
                    name: "Inväntar bekräftelset",
                    time: moment().unix(),
                    description: "Ärende Skapats",
                  },
                ],
                token: tokenvalue,
                leverans: value,
                timeCreated: moment().unix(),
              })
              .then(() => {
                db.collection("tickets")
                  .doc("mainId")
                  .set({ mainTicketId: mainId + 1 });
                sendSMS(mainId + 1, formValues.name, formValues.email);
              })
              .catch((e) => {
                console.error("error", e);
              });
          } else {
            //add fel hantering
          }
        })
        .catch((error) => {});
    }
    if (activeStep === 2) {
      handleReset();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setProg(false);
    setTicketId(false);
    setActiveService("");
    setActiveVariation("");
    setActiveProduct("");
    setActiveProduct("");
    //setProductsArray([]);
    setActiveButton("");
    setFormValuesError(defaultValuesErrors);
    setFormValues(defaultValues);
    setprint(false);
    //window.location.reload();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setFormValuesError({
      ...setFormValuesError,
      [name]: false,
    });
  };

  const handelFile = async (e) => {
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child("images/" + file.name).put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume Get task
        // progress, including the number of bytes uploaded and the total number of
        // bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProg(progress);

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
          default:
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete For instance, get the download URL:
        // https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setFormValues({
            ...formValues,
            imageUrl: downloadURL,
            fileName: file.name,
          });
          setOpen(true);
        });
      }
    );
  };

  const steps = [
    {
      label: "Klicka på den enhet du önskar att reparera",
      description: ``,
      button: (
        <div>
          <Collapse in={true}>
            <StackButtons
              width="fit-content"
              flexWrap="wrap"
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {productsArray.length > 0 &&
                productsArray.map((x) => (
                  <Button
                    style={{ marginBottom: 12 }}
                    value={x.name}
                    key={x.name}
                    onClick={() => {
                      handel(x.id);
                    }}
                    variant={activeButton === x.id ? "contained" : "outlined"}
                  >
                    {x.name}
                  </Button>
                ))}
            </StackButtons>
          </Collapse>
          {activeButton !== "" && <Divider sx={{ mb: 1 }} />}
          {variationArr.length > 0 && (
            <StackButtons
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              flexWrap="wrap"
              rowGap={1}
            >
              {variationArr.map((x) => (
                <Button
                  variant={activeVariation === x ? "contained" : "outlined"}
                  color="secondary"
                  key={x}
                  onClick={() => {
                    handelVariation(x);
                  }}
                >
                  {x}
                </Button>
              ))}
            </StackButtons>
          )}
          {activeVariation !== "" && <Divider sx={{ mb: 1 }} />}
          {servicesArr.length > 0 && (
            <StackButtons
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              flexWrap="wrap"
              rowGap={1}
            >
              {servicesArr.map((x) => (
                <Button
                  disabled={!x.stripPriceid}
                  variant={
                    activeService.includes(x.name) > 0
                      ? "contained"
                      : "outlined"
                  }
                  color="service"
                  key={x.id}
                  onClick={() => {
                    if (activeService.includes(x.name)) {
                      setActiveService(
                        activeService.filter((e) => e !== x.name)
                      );
                    } else {
                      setActiveService([...activeService, x.name]);
                    }
                  }}
                >
                  {x.name}
                </Button>
              ))}
            </StackButtons>
          )}
        </div>
      ),
    },
    {
      label: "Skapa ett ärende",
      description:
        "Fyll i dina uppgifter och välj om du vill lämna in datorn på vår reparationscenter eller o vill ta del av vår kostnadsfria inskickningservice",
      button: (
        <>
          <Box
            component="form"
            sx={{
              "& > :not(style)": {
                m: 1,
              },
            }}
            noValidate
            autoComplete="on"
          >
            <TextField
              helperText={formValuesError.nameHelper}
              color={"secondary"}
              error={formValuesError.name}
              required
              id="name-input"
              name="name"
              label="Namn"
              type="text"
              variant="standard"
              value={formValues.name}
              onChange={handleInputChange}
            />
            <TextField
              helperText={formValuesError.emailHelper}
              required
              sx={{ width: "100%" }}
              error={formValuesError.email}
              color={"secondary"}
              id="E-mail"
              label="E-mail"
              variant="standard"
              name="email"
              type="text"
              value={formValues.email}
              onChange={handleInputChange}
            />
            <TextField
              required
              sx={{ width: "100%" }}
              error={formValuesError.adress}
              color={"secondary"}
              id="Address"
              label="Adress"
              variant="standard"
              name="adress"
              type="text"
              value={formValues.adress}
              onChange={handleInputChange}
            />
            <FormControl sx={{ m: 1, minWidth: 120, minHeight: 48 }}>
              <InputLabel id="demo-simple-select-label">land</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={countryCod}
                label="Code"
                onChange={handleChange}
              >
                <MenuItem value={"46"}>Sverige</MenuItem>
                <MenuItem value={"45"}>Danmark</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              error={formValuesError.mobil}
              color={"secondary"}
              id="mobile-input"
              label="Mobil"
              variant="standard"
              name="mobil"
              type="text"
              value={formValues.mobil}
              onChange={handleInputChange}
              placeholder="777777777"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    +{countryCod}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              multiline
              sx={{ width: "100%" }}
              error={formValuesError.describtion}
              color={"secondary"}
              id="description"
              label="Beskriv felet"
              name="describtion"
              value={formValues.describtion}
              onChange={handleInputChange}
              variant="standard"
              rows={3}
            />
            <Box>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handelFile}
                />
                <Button
                  color={"secondary"}
                  variant="contained"
                  component="span"
                >
                  Ladda upp en fil
                </Button>
              </label>
              {prog && (
                <div>
                  <Progresbar
                    color={"secondary"}
                    variant="determinate"
                    value={prog}
                  />
                  <span>
                    {prog}
                    {" %"}
                  </span>
                </div>
              )}
              {formValues.imageUrl !== "" && (
                <img src={formValues.imageUrl} alt={"asd"} width="150" />
              )}
            </Box>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Lämna / Skicka
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            >
              <FormControlLabel
                value="Skicka"
                control={<Radio />}
                label="Skicka in kostnadsfritt"
              />
              <FormControlLabel
                value="Lämna"
                control={<Radio />}
                label="Lämna in på reparationscenter"
              />
            </RadioGroup>

            <Typography>
              Fler bilder kan laddas upp efter add ärende skapas
            </Typography>
          </Box>
          <Box m={0} marginTop={2}>
            <Typography>
              Genom att klicka på "SKAPA" godkänner du{" "}
              <Link
                target="_blank"
                href="https://laptopclinic.se/pages/privacy-policy"
              >
                LAPTOPCLINIC ALLMÄNNA VILLKOR
              </Link>
            </Typography>
          </Box>
        </>
      ),
    },
    {
      label: "Ärendenummer",
      description: `Tack för att du kontaktar Laptop Clinic. Vi har nu mottagit din förfrågan, en av våra experter kommer att återkomma till dig så snart som möjligt. Ha en bra dag!`,
      button: (
        <div>
          <h2>
            {"Ditt ärendenummer är "}
            {ticketId}
          </h2>
        </div>
      ),
    },
  ];

  return (
    <CaseContiner>
      <Grid container px={6} justifyContent="space-evenly">
        <Grid item xs={12} md={6}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === 2 ? (
                      <Typography variant="caption">Sista steg</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography mb={1}>{step.description}</Typography>
                  {step.button}

                  <Box
                    sx={{
                      mb: 2,
                    }}
                  >
                    <div>
                      {props.user && caseId ? (
                        <Button
                          onClick={updateCase}
                          variant="contained"
                          sx={{
                            mt: 1,
                            mr: 1,
                          }}
                        >
                          Add services to the case
                        </Button>
                      ) : (
                        <>
                          {" "}
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{
                              mt: 1,
                              mr: 1,
                            }}
                          >
                            {index === steps.length - 2
                              ? "Skapa"
                              : index === steps.length - 1
                              ? "Ny ärende"
                              : "Nästa"}
                          </Button>
                          <Button
                            disabled={index === 0 || index === 2}
                            onClick={handleBack}
                            sx={{
                              mt: 1,
                              mr: 1,
                            }}
                          >
                            Föregående
                          </Button>
                        </>
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} md={4}>
          <PriceShow
            service={activeService}
            product={activeButton}
            variation={activeVariation}
          />
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        severity="success"
        autoHideDuration={10000}
        onClose={handleClose}
        message="Fil uppladdat"
      />
    </CaseContiner>
  );
}

const CaseContiner = styled(Box)({ margin: "auto" });

const StackButtons = styled(Stack)({ marginBottom: "12px" });

const Progresbar = styled(LinearProgress)({ marginTop: "12px" });
