import * as React from 'react';
import {createTheme} from '@mui/material/styles';
import { Button } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#d80007'
        },
        secondary: {
            main: '#673ab7'
        },
        service:{
            main: '#009688'
        }
    }
});

export default theme