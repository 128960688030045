import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";

const RichTextEditor = ({ onContentChange, clearEditor }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleClearButtonClick = () => {
    const emptyEditorState = EditorState.createEmpty();
    setEditorState(emptyEditorState);
    onContentChange("");
  };

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const htmlContent = stateToHTML(newEditorState.getCurrentContent());
    onContentChange(htmlContent);
  };

  useEffect(() => {
    if (clearEditor) {
      const emptyEditorState = EditorState.createEmpty();
      setEditorState(emptyEditorState);
      onContentChange("");
    }
  }, [clearEditor, onContentChange]);

  const toolbarOptions = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "link",
      "emoji",
      "remove",
      "history",
    ],
    embedded: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      icon: undefined,
      insertCallback: undefined,
    },
  };
  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        toolbar={toolbarOptions}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        editorStyle={{ border: "solid 1px" }}
        placeholder="Skriv till oss här om du behöver hjälp angående ditt ärende"
      />
      <button type="button" onClick={handleClearButtonClick}>
        Clear
      </button>
    </div>
  );
};

export default RichTextEditor;
