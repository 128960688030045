import React, { useEffect, useState } from "react";
import firebase from "./firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider, Grid, TextField } from "@mui/material";
import CustomizedTimeline from "./timeLine";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ChatBlock from "./ChatBlock";
import moment from "moment";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import "moment/locale/sv";
import SendMessage from "./sendmessage";
import Edit from "./editfiled";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Shift from "./shift";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; // Change this line for Moment adapter
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";

var db = firebase.firestore();

export default function CasePage(props) {
  let navigate = useNavigate();
  const { state } = useLocation();
  const caseId = state;
  const [ticketId, setTicketId] = useState(
    props.caseId.toString() || caseId.caseId
  );
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState("");
  const [caseData, setCaseData] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [checked, setChecked] = useState(false);
  const [notify, setNotufy] = useState(false);
  const [update, setUpdate] = useState(false);
  const [total, setTotal] = useState("");
  const [item, setItem] = useState("");
  const [price, setPrice] = useState("");
  const [left, setLeft] = useState("");
  const [express, setExpress] = useState(false);
  const [date, setDate] = useState("");

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handelCheck = (event) => {
    setChecked(event.target.checked);
  };
  const handelExpress = (event) => {
    setOpen(true);
    db.collection("tickets")
      .doc(ticketId)
      .update({
        express: !express,
      })
      .then(() => {
        setUpdate(!update);
      })
      .then(() => setOpen(false));
  };

  const clearNotify = () => {
    db.collection("tickets").doc(ticketId).update({
      notify: false,
    });
    setNotufy(false);
  };

  const updatedb = (faild, value) => {
    let obj = {};
    obj[faild] = value;

    db.collection("tickets")
      .doc(ticketId)
      .update(obj)
      .then(() => {
        setUpdate(!update);
      });
  };

  const additem = () => {
    let array = caseData.costs || [];
    let obj = {
      item: item,
      price: price,
      time: moment().unix(),
      paid: false,
      paidTime: moment().unix(),
    };
    array.push(obj);
    db.collection("tickets")
      .doc(ticketId)
      .update({
        costs: array,
      })
      .then(() => {
        setItem("");
        setPrice("");
        setUpdate(!update);
      });
  };

  const deleteItem = (item) => {
    setOpen(true);
    let arr = caseData.costs;
    if (arr) {
      let find = arr.find((el) => el.item === item);
      let index = arr.indexOf(find);

      if (index > -1) {
        arr.splice(index, 1);
      }
      db.collection("tickets")
        .doc(caseData.ticketId.toString())
        .update({ costs: arr })
        .then(() => {
          setUpdate(!update);
          setOpen(false);
        });
    }
  };

  const paidCheck = (e, item) => {
    setOpen(true);
    let arr = caseData.costs;
    if (arr) {
      let find = arr.find((el) => el.item === item);
      let index = arr.indexOf(find);

      if (index > -1) {
        arr[index].paid = e.target.checked;
        arr[index].paidTime = moment().unix();
      }
      db.collection("tickets")
        .doc(caseData.ticketId.toString())
        .update({ costs: arr })
        .then(() => {
          setUpdate(!update);
          setOpen(false);
        });
    }
  };

  console.log("this is case data ----- ", caseData);

  useEffect(() => {
    if (!props.logged) {
      navigate("/");
    }
    moment.locale("sv");

    if (ticketId) {
      let caseRef = db.collection("tickets").doc(ticketId);
      caseRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            setCaseData(doc.data());
            setNotufy(data.notify);
            setExpress(data.express || false);
            let costs = data.costs;
            if (costs) {
              let totalarray = [];
              let totalLeft = [];
              for (let i = 0; i < costs.length; i++) {
                const element = costs[i];
                totalarray.push(element.price);
                if (!element.paid) {
                  totalLeft.push(element.price);
                }
              }
              let total = totalarray.reduce((a, b) => a + b, 0);
              let lefttotal = totalLeft.reduce((a, b) => a + b, 0);
              console.log(
                "this is the total",
                total,
                "and left is ",
                lefttotal
              );
              setLeft(lefttotal);
              setTotal(total);
            }
          } else {
          }
        })
        .catch((e) => {});
      let statusArr = [];
      db.collection("statuses")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((x) => {
            // doc.data() is never undefined for query doc snapshots

            statusArr.push(x.data());
          });
        })
        .then(() => {
          setStatuses(statusArr);
        });
    }
  }, [update]);

  const updateStatus = () => {
    let caseRef = db.collection("tickets").doc(ticketId);

    let currentStatus = caseData.status;
    if (checked) {
      caseRef.update({
        currentStatus: parseInt(status),
      });
      return;
    } else {
      let stuatsName = statuses.find((element) => element.id === status);

      let newStatus = {
        id: status,
        name: stuatsName.name,
        time: moment().unix(),
        description: stuatsName.description,
      };
      currentStatus.push(newStatus);

      return caseRef
        .update({
          status: currentStatus,
          currentStatus: parseInt(status),
        })
        .then(() => {
          let ctrycode = caseData.countryCode ? caseData.countryCode : "46";

          SendMessage(
            "",
            caseData.mobil,
            caseData.ticketId,
            "0",
            caseData.token,
            "",
            ctrycode,
            false,
            caseData.email,
            caseData.name,
            true
          );
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  };

  const pay = () => {
    if (caseData.costs) {
      let data = JSON.stringify(caseData.costs);
      axios
        .get(
          `https://helical-arcade-322422.ew.r.appspot.com/paymenlink?price=${data}&orderNr=${caseData.ticketId}`
        )
        .then((x) => {
          db.collection("tickets")
            .doc(caseData.ticketId.toString())
            .update({ paymentLink: x.data.url, paymentLinkId: x.data.id })
            .then(() => {
              SendMessage(
                "",
                caseData.mobil,
                caseData.ticketId,
                "0",
                caseData.token,
                "",
                caseData.countryCod,
                false,
                caseData.email,
                "LPT",
                true
              );
              setUpdate(!update);
            });
        });
    }
  };

  const updatEtd = (time) => {
    let caseRef = db.collection("tickets").doc(ticketId);
    caseRef.update({
      etd: time,
    });
  };

  return (
    <Grid container maxWidth={1200} margin={"auto"} mb={10}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        //onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </Backdrop>
      {caseData && (
        <>
          <Grid item md={8} xs={12}>
            <Card>
              <CardMedia
                component="img"
                height="maxContent"
                image={caseData.imageUrl}
                alt="Case image"
              />
              <CardContent>
                <Grid container direction={"row"}>
                  <Typography mb="25px" variant="h5" component="div" mr={8}>
                    {caseData.type[0]} {caseData.type[1]} {caseData.type[2]}
                  </Typography>
                  <Badge
                    badgeContent=" "
                    color="primary"
                    invisible={!notify}
                    onClick={clearNotify}
                  >
                    <NotificationsActiveIcon color="action" />
                  </Badge>
                </Grid>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>{caseData.ticketId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>
                          <Edit
                            text={caseData.name}
                            ticketid={caseData.ticketId}
                            obj="name"
                            update={() => {
                              setUpdate(!update);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>country Code</TableCell>
                        <TableCell>
                          <Edit
                            text={caseData.countryCod}
                            ticketid={caseData.ticketId}
                            obj="countryCod"
                            update={() => {
                              setUpdate(!update);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>
                          <Edit
                            text={caseData.mobil}
                            ticketid={caseData.ticketId}
                            obj="mobil"
                            update={() => {
                              setUpdate(!update);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>email</TableCell>
                        <TableCell>
                          <Edit
                            text={caseData.email}
                            ticketid={caseData.ticketId}
                            obj="email"
                            update={() => {
                              setUpdate(!update);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Adress</TableCell>
                        <TableCell>
                          <Edit
                            text={caseData.adress}
                            ticketid={caseData.ticketId}
                            obj="adress"
                            update={() => {
                              setUpdate(!update);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>
                          <Edit
                            text={caseData.describtion}
                            ticketid={caseData.ticketId}
                            obj="describtion"
                            update={() => {
                              setUpdate(!update);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Leverans</TableCell>
                        <TableCell>
                          <Edit
                            text={caseData.leverans || ""}
                            ticketid={caseData.ticketId}
                            obj="leverans"
                            update={() => {
                              setUpdate(!update);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Typography mt={1} variant="h5">
                      Status
                    </Typography>
                    <InputLabel id="demo-simple-select-label">
                      Select a Status <br />
                      Check för internal comment status
                    </InputLabel>
                    <Checkbox checked={checked} onChange={handelCheck} />
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Age"
                      onChange={handleChange}
                    >
                      {statuses.map((status) => (
                        <MenuItem value={status.id}>{status.name}</MenuItem>
                      ))}
                    </Select>
                    <Typography variant="h6">Express</Typography>
                    <Checkbox checked={express} onChange={handelExpress} />
                  </Grid>
                  <Grid item md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <StaticDatePicker
                        onAccept={(e) => updatEtd(e.unix())}
                        defaultValue={moment.unix(caseData.etd) || moment()}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button size="small" onClick={updateStatus}>
                  Update Status
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomizedTimeline statuses={caseData.status} />
          </Grid>
          <Grid item md={6} xs={12}>
            <ChatBlock
              view="0"
              caseData={caseData}
              internal={checked}
              logged={props.logged}
              changeInternal={(e) => {
                setChecked(e);
              }}
            />
          </Grid>
          <Grid item md={4} xs={12} mt={2} ml={1}>
            <Typography variant="h4" mb={1}>
              Payment
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <Alert variant="filled" severity="info">
                Total is {total} kr
              </Alert>
            </Box>
            {left > 0 && (
              <Box display="flex" alignItems="center" mb={1}>
                <Alert variant="filled" severity="warning">
                  Left to pay is {left} kr
                </Alert>
              </Box>
            )}
            {caseData.paid && (
              <Box display="flex" alignItems="center" mb={1}>
                <Alert variant="filled" severity="success">
                  Full amount paid via stripe
                </Alert>
              </Box>
            )}
            <Box mb={1}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/?id=${caseData.ticketId}`);
                }}
              >
                Add costs
              </Button>
            </Box>
            {caseData.costs && (
              <Box>
                <Typography variant="h5">Costs</Typography>
                {caseData.costs.map((x) => (
                  <Box>
                    <TableRow>
                      <TableCell>{x.item}</TableCell>
                      <TableCell>
                        {x.price} kr{" "}
                        <p
                          style={{
                            color: "red",
                            fontStyle: "italic",
                            textDecoration: "underline",
                          }}
                        >
                          {x.cost}
                        </p>
                      </TableCell>

                      <TableCell>
                        {moment.unix(x.time).format("DD MM YYYY hh:mm")}
                      </TableCell>
                      <TableCell>
                        {
                          <DeleteIcon
                            onClick={() => {
                              deleteItem(x.item);
                            }}
                          />
                        }
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={x.paid}
                          onChange={(e) => {
                            paidCheck(e, x.item);
                          }}
                        />
                      </TableCell>

                      <Tooltip title={x.user}>
                        <TableCell>
                          {moment.unix(x.paidTime).format("DD MM YYYY hh:mm") ||
                            "no data found"}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  </Box>
                ))}
              </Box>
            )}
            {!caseData.paymentLink && (
              <Button variant="contained" onClick={pay}>
                Send payment
              </Button>
            )}

            <Divider />
            <Box>
              <Typography variant="h5">Time report</Typography>
              <Shift
                user={props.user}
                ticketId={caseData.ticketId}
                current={caseData.currentShift}
                update={() => setUpdate(!update)}
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}
