import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/styles";
import Button from "@mui/material/Button";
import firebase from "./firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import "firebase/auth";

var db = firebase.firestore();

export default function LogIn(props) {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let filter = searchParams.get("id");

  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");

  const firebaseLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(userName, passWord)
      .then((x) => {
        if (x.user) {
          navigate("/list");
        }
      });
  };

  return (
    <Container
      component="form"
      sx={{
        maxWidth: 500,
      }}
      noValidate
      autoComplete="on"
    >
      <InputFiled
        type="text"
        id="username"
        name="username"
        variant="outlined"
        label="User Name"
        value={userName}
        onChange={(e) => {
          setUserName(e.target.value);
        }}
        required
      />
      <InputFiled
        type="password"
        id="pass"
        name="password"
        value={passWord}
        onChange={(e) => {
          setPassWord(e.target.value);
        }}
        variant="outlined"
        label="Pass Word"
        required
      />
      <Button onClick={firebaseLogin} variant="contained">
        Login
      </Button>
    </Container>
  );
}

const Container = styled(Box)({ marginTop: "24px", textAlign: "center" });
const InputFiled = styled(TextField)({ margin: "8px" });
