import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import firebase from "./firebase";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import theme from "./theme";
import moment from "moment";
import "moment/locale/sv";

import "firebase/auth";
var db = firebase.firestore();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function List(props) {
  let navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState(1);
  const [searchId, setSearchId] = useState("");
  const [checked, setChecked] = useState(false);
  const [express, setExpress] = useState(false);
  const [etd, setEtd] = useState(false);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleCeck = (event, st) => {
    if (event.target.checked) {
      let arr = [];
      db.collection("tickets")
        .where(st, "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let nee = doc.data();

            arr.push(nee);
          });
        })
        .then(() => {
          setTickets(arr);
        })
        .catch((error) => {});
    } else {
      let arr = [];
      db.collection("tickets")
        .where("currentStatus", "==", parseInt(status))
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let nee = doc.data();

            arr.push(nee);
          });
        })
        .then(() => {
          setTickets(arr);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    /*     firebase.auth().onAuthStateChanged((user) => {
      
      if (!user) {
        navigate("/");
      }
    }); */

    let currentStatus = parseInt(status);

    let arr = [];
    let obj = {
      id: "1",
    };
    db.collection("tickets")
      .where("currentStatus", "==", currentStatus)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let nee = doc.data();

          arr.push(nee);
        });
      })
      .then(() => {
        console.log(arr);
        setTickets(arr);
      })
      .catch((error) => {});
    let statusArr = [];
    db.collection("statuses")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((x) => {
          // doc.data() is never undefined for query doc snapshots

          statusArr.push(x.data());
        });
      })
      .then(() => {
        setStatuses(statusArr);
      });
  }, [status]);

  const search = () => {
    let arr = [];
    if (searchId.length > 5 && !searchId.includes("@")) {
      db.collection("tickets")
        .where("mobil", "==", searchId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let nee = doc.data();

            arr.push(nee);
          });
        })
        .then(() => {
          setTickets(arr);
        })
        .catch((error) => {});
    } else if (searchId.includes("@")) {
      db.collection("tickets")
        .where("email", "==", searchId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let nee = doc.data();

            arr.push(nee);
          });
        })
        .then(() => {
          setTickets(arr);
        })
        .catch((error) => {});
    } else {
      props.setid(searchId);
      navigate("/case");
    }
  };

  const getEtd = (e) => {
    let arr = [];
    console.log(e.target.checked);
    if (e.target.checked) {
      console.log(parseInt(status));
      db.collection("tickets")
        .where("etd", ">", 0)
        .where("currentStatus", "==", parseInt(status))
        .orderBy("etd")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let nee = doc.data();
            console.log(nee);
            arr.push(nee);
          });
        })
        .then(() => {
          setTickets(arr);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    } else {
      db.collection("tickets")
        .where("currentStatus", "==", parseInt(status))
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let nee = doc.data();

            arr.push(nee);
          });
        })
        .then(() => {
          setTickets(arr);
        })
        .catch((error) => {});
    }
  };

  return (
    <Grid m="auto" justifyContent="center" container maxWidth={1200}>
      <Grid item mb={1} mr={2}>
        <Typography mt={1} variant="h5">
          Status
        </Typography>
        <InputLabel id="demo-simple-select-label">Select a Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Age"
          onChange={handleChange}
        >
          {statuses.map((status) => (
            <MenuItem value={status.id}>{status.name}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item mt={2} direction={"column"}>
        <Grid item mb={1}>
          <TextField
            onKeyDown={(e) => {
              let code = e.keyCode ? e.keyCode : e.which;

              if (code === 13) {
                search();
              }
            }}
            onChange={(e) => {
              setSearchId(e.target.value);
            }}
            id="outlined-basic"
            label="Sök"
            variant="outlined"
          />
        </Grid>
        <Grid item textAlign={"end"}>
          <Button
            variant="contained"
            onClick={() => {
              search();
            }}
          >
            Sök
          </Button>
        </Grid>
      </Grid>
      <Grid item ml={4} textAlign={"end"}>
        <Typography>Notifications</Typography>
        <Switch
          checked={checked}
          onChange={(e) => {
            handleCeck(e, "notify");
            setChecked(e.target.checked);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>Express</Typography>
        <Switch
          checked={express}
          onChange={(e) => {
            handleCeck(e, "express");
            setExpress(e.target.checked);
          }}
          input
          Props={{ "aria-label": "controlled" }}
        />
        <Typography>ETD</Typography>
        <Switch
          checked={etd}
          onChange={(e) => {
            getEtd(e);
            setEtd(e.target.checked);
          }}
          input
          Props={{ "aria-label": "controlled" }}
        />
      </Grid>
      <TableContainerStyled component={Paper}>
        <Table
          sx={{
            minWidth: 800,
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>TicketId</StyledTableCell>
              <StyledTableCell align="right">Case</StyledTableCell>
              <StyledTableCell align="right">Name</StyledTableCell>
              <StyledTableCell align="right">E-mail</StyledTableCell>
              <StyledTableCell align="right">Image</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right">Time created</StyledTableCell>
              <StyledTableCell align="right">ETD</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((row) => (
              <StyledTableRow
                key={row.ticketId}
                style={{
                  backgroundColor: row.notify ? theme.palette.service.main : "",
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.ticketId}
                </StyledTableCell>
                <StyledTableCell align="right">{row.type}</StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">{row.email}</StyledTableCell>
                <StyledTableCell align="right">
                  <img width={75} src={row.imageUrl} alt="s" />
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.setid(row.ticketId);
                      navigate("/case");
                    }}
                  >
                    Open
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  {moment.unix(row.timeCreated).format("YYYY-MM-DD HH:mm")}
                </StyledTableCell>
                <StyledTableCell>
                  {moment.unix(row.etd).format("YYYY-MM-DD HH:mm")}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
    </Grid>
  );
}

const TableContainerStyled = styled(TableContainer)({ margin: "auto" });
