import { Info } from "@mui/icons-material";
import {
  Alert,
  Card,
  CardContent,
  Divider,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import firebase from "./firebase";

var db = firebase.firestore();
export default function PriceShow({ service, product, variation }) {
  const [pricearr, setPricearr] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (service && product && variation) {
      let arr = [];
      let total = [];
      for (let index = 0; index < service.length; index++) {
        const element = service[index];
        db.collection("products")
          .doc(product)
          .collection("variant")
          .doc(variation)
          .collection("services")
          .doc(element)
          .get()
          .then((data) => {
            db.collection("services")
              .doc(element)
              .get()
              .then((serD) => {
                let serivceData = data.data();
                let serviceGData = serD.data();

                let obj = {
                  service: serivceData,
                  gData: serviceGData,
                };

                total.push(Number(serivceData.price));
                arr.push(obj);
                if (index === service.length - 1) {
                  setTotalPrice(
                    total.reduce((partialSum, a) => partialSum + a, 0)
                  );
                  setPricearr(arr);
                }
              });
          });
      }
    }
  }, [service]);
  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h5">Pris</Typography>
          <Divider />
          {pricearr.map((item) => (
            <Box>
              <TableRow>
                <TableCell>{item.gData.name}</TableCell>
                <TableCell>{item.service.price} kr</TableCell>
                <TableCell>
                  <Tooltip title={item.gData.description}>
                    <Info color="primary" />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </Box>
          ))}
          <Alert variant="filled" severity="info">
            Total is {totalPrice} kr
          </Alert>
        </CardContent>
      </Card>
    </Box>
  );
}
