import "./App.css";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function NavBar(props) {
  let navigate = useNavigate();

  return (
    <div className="App">
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <AppBar position="static">
          <Toolbar>
            <Typography
              onClick={() => navigate("/")}
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
              }}
            >
              Laptopclinic
            </Typography>
            {props.logged && (
              <>
                <Button
                  onClick={() => {
                    navigate("/list");
                  }}
                  color="inherit"
                >
                  List Orders
                </Button>
                <Button
                  onClick={() => {
                    navigate("/addproduct");
                  }}
                  color="inherit"
                >
                  Add product
                </Button>
              </>
            )}
            <Button onClick={() => navigate("/")} color="inherit">
              Track Case
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}

export default NavBar;
