import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Typography } from "@mui/material";
/* import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert"; */
import firebase from "./firebase";
var moment = require("moment");

function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split("e");
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
}
const round10 = (value, exp) => decimalAdjust("round", value, exp);

var db = firebase.firestore();

export default function Shift(props) {
  var batch = db.batch();

  const [shifts, setShifts] = useState([]);
  const [time, setTime] = useState("");
  const [timeStart, setTimeStart] = useState(false);
  const [timeEnd, setTimeEnd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [totalTime, setTotaltime] = useState("");

  useEffect(() => {
    moment.locale("sv");
    let shiftsArray = [];
    let total = [];
    if (props.current) {
      db.collection("tickets")
        .doc(props.ticketId.toString())
        .collection("shifts")
        .doc(props.current)
        .get()
        .then((doc) => {
          let data = doc.data();
          setTimeStart(data.TimeStarted);
        });
    }
    db.collection("tickets")
      .doc(props.ticketId.toString())
      .collection("shifts")
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let data = doc.data();

          shiftsArray.push(data);
          let dur = moment.duration(data.TotalTime);
          let durmin = Math.floor(moment.duration(data.TotalTime).asMinutes());
          let totalHr = round10(moment.duration(data.TotalTime).asHours(), -1);
          total.push(totalHr);
        });
      })
      .then(() => {
        setTotaltime(total.reduce((a, b) => a + b, 0));
        setShifts(shiftsArray);
      });
  }, [update]);

  function startshift() {
    db.collection("tickets")
      .doc(props.ticketId.toString())
      .collection("shifts")
      .add({
        TimeStarted: moment().format("MMMM Do YYYY, h:mm:ss a"),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        month: moment().format("MMMM"),
      })
      .then((doc) => {
        setTimeStart(moment().format("MMMM Do YYYY, h:mm:ss a"));
        db.collection("tickets")
          .doc(props.ticketId.toString())
          .update({ currentShift: doc.id })
          .then(() => props.update());
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  function endshift(l) {
    //l is lunch
    if (timeStart === "") {
      alert("You need to start your shift first ");
      return;
    }
    if (timeEnd) {
      alert("You have already Ended your shift for today");
      return;
    }
    let shift = db
      .collection("tickets")
      .doc(props.ticketId.toString())
      .collection("shifts")
      .doc(props.current);
    let ticket = db.collection("tickets").doc(props.ticketId.toString());
    setTimeEnd(moment().format("MMMM Do YYYY, h:mm:ss a"));
    batch.update(shift, {
      timeEnd: moment().format("MMMM Do YYYY, h:mm:ss a"),
    });

    var ms = moment(
      moment().format("MMMM Do YYYY, h:mm:ss a"),
      "MMMM Do YYYY, h:mm:ss a"
    ).diff(moment(timeStart, "MMMM Do YYYY, h:mm:ss a"));

    var d = moment.duration(ms);

    var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

    setTime(s);
    batch.update(shift, { user: props.user });
    batch.update(shift, { TotalTime: s });
    batch.update(ticket, { currentShift: false });
    batch.commit();
    setUpdate(!update);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <p>Shift Started</p>
        {timeStart}
      </Grid>
      <Grid item xs={6}>
        <p>Shift Ended</p>
        {timeEnd}
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={props.current ? true : false}
          variant="contained"
          color="primary"
          onClick={() => {
            startshift();
          }}
        >
          Start shift
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={timeEnd ? true : false}
          variant="contained"
          color="primary"
          onClick={() => {
            endshift();
          }}
        >
          End shift
        </Button>
      </Grid>
      <Grid item xs={12}>
        <p>Current shift</p>
        {time}
      </Grid>
      {shifts.length && (
        <Grid>
          <Typography variant="h6">Past shifts</Typography>
          <Alert variant="filled" severity="info">
            Total time is <b>{totalTime}</b> HR
          </Alert>
          {shifts.map((sh) => (
            <>
              <Typography>{sh.TotalTime}</Typography>
            </>
          ))}
        </Grid>
      )}
    </Grid>
  );
}
