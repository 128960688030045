import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import firebase from "./firebase";
import CustomizedTimeline from "./timeLine";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import ChatBlock from "./ChatBlock";
import Alert from "@mui/material/Alert";

var db = firebase.firestore();

export default function ClientPage(props) {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let caseId = searchParams.get("id");
  let token = searchParams.get("token");
  const [total, setTotal] = useState("");
  const [left, setLeft] = useState("");

  let [caseData, setCaseData] = useState(null);

  useEffect(() => {
    if (caseId) {
      let caseRef = db.collection("tickets").doc(caseId);
      caseRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            setCaseData(data);
            let costs = data.costs;
            if (costs) {
              let totalarray = [];
              let totalLeft = [];
              for (let i = 0; i < costs.length; i++) {
                const element = costs[i];
                totalarray.push(element.price);
                if (!element.paid) {
                  totalLeft.push(element.price);
                }
              }
              let total = totalarray.reduce((a, b) => a + b, 0);
              let lefttotal = totalLeft.reduce((a, b) => a + b, 0);
              console.log(
                "this is the total",
                total,
                "and left is ",
                lefttotal
              );
              setLeft(lefttotal);
              setTotal(total);
            }
          } else {
          }
        })
        .catch((e) => {});
    }
  }, []);

  return (
    <Grid container maxWidth={1200} margin={"auto"}>
      {caseData && (
        <>
          <Grid item md={8} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h4">Ditt ärende status</Typography>
                <Typography mb={1} variant="h5">
                  {caseData.status[caseData.status.length - 1].name}
                </Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{caseData.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>{caseData.mobil}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>email</TableCell>
                        <TableCell>{caseData.email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Adress</TableCell>
                        <TableCell>{caseData.adress}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>{caseData.describtion}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {caseData.costs && (
                    <Box>
                      {caseData.costs.map((x) => (
                        <Box>
                          <TableRow>
                            <TableCell>{x.item}</TableCell>
                            <TableCell>{x.price} kr</TableCell>
                          </TableRow>
                        </Box>
                      ))}
                    </Box>
                  )}
                </TableContainer>
              </CardContent>
              <CardActions sx={{ "justify-content": "end" }}>
                {caseData.paymentLink &&
                  caseData.paymentLink !== "" &&
                  !caseData.paid && (
                    <Button
                      disabled={caseData.paid}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        window.open(caseData.paymentLink);
                      }}
                    >
                      Betala
                    </Button>
                  )}
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomizedTimeline statuses={caseData.status} />
          </Grid>
          <Grid item md={6} xs={12}>
            <ChatBlock
              view="1"
              caseData={caseData}
              internal={false}
              logged={props.logged}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
