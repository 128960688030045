import axios from "axios";
import firebase from "./firebase";
import moment from "moment";

var db = firebase.firestore();
export default function SendMessage(
  message,
  number,
  ticketId,
  sender,
  token,
  imageLink,
  ctrycod,
  internal,
  email,
  name,
  notify,
  content
) {
  return new Promise((res, rej) => {
    let id = ticketId.toString();
    let ctrcode = ctrycod ? ctrycod : "46";
    let smsnr = ctrcode + number.replace(/^0+/, "");
    let smstosend =
      " -Laptopclinic- Du har fått nytt meddelande i ditt ärende " + id;
    if (sender === "0" && !internal && notify) {
      axios.post(
        `https://helical-arcade-322422.ew.r.appspot.com/sendemail?email=${email}&ticket=${ticketId}&token=${token}&name=${name}&sms=${smstosend}`
      );
    }
    if (internal) {
      db.collection("tickets")
        .doc(ticketId.toString())
        .collection("internalMessages")
        .doc(moment().unix().toString())
        .set({
          time: moment().unix(),
          sender: sender,
          message: message,
          image: imageLink,
          internal: internal,
          content: content,
        })
        .then(() => {
          res("message sent");
        })
        .catch((e) => {});
    } else {
      db.collection("tickets")
        .doc(ticketId.toString())
        .collection("messages")
        .doc(moment().unix().toString())
        .set({
          time: moment().unix(),
          sender: sender,
          message: message,
          image: imageLink,
          internal: internal,
          content: content,
        })
        .then(() => {
          res("message sent");
          if (sender === "1") {
            db.collection("tickets").doc(ticketId.toString()).update({
              notify: true,
            });
          }
        })
        .catch((e) => {});
    }
  });
}
