import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import theme from "./theme";
import { Typography } from "@mui/material";
import moment from "moment";

import { styled } from "@mui/material/styles";
import "moment/locale/sv";
export default function ChatBox(props) {
  moment.locale("sv");
  function bgcolor(params) {
    if (props.internal) {
      return theme.palette.service.main;
    } else if (props.align === "flex-end") {
      return theme.palette.primary.main;
    } else {
      return theme.palette.secondary.main;
    }
  }
  var dateString = moment.unix(props.time).format("DD MM YYYY hh:mm");
  return (
    <Box display="flex" flexWrap="wrap" justifyContent={props.align} mb={1}>
      <Box
        bgcolor={bgcolor()}
        borderRadius={`${props.align === "flex-start" ? "0px" : "16px"} ${
          props.align === "flex-end" ? "0px" : "16px"
        } 16px 16px`}
        color="white"
        width="fit-content"
        maxWidth={350}
      >
        <Typography variant="caption" ml={1} mr={1}>
          {dateString}
        </Typography>
        {props.text !== "" && (
          <Typography variant="body1" ml={1} mr={1} padding={1}>
            {props.text}
          </Typography>
        )}

        {props.img && props.img !== "" && (
          <img
            style={{ borderRadius: "20px" }}
            src={props.img}
            alt={"asd"}
            width="150"
          />
        )}
        {props.content !== "" && (
          <Box
            ml={1}
            mr={1}
            padding={1}
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        )}
      </Box>
    </Box>
  );
}
