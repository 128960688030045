import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import firebase from "./firebase";
import { useNavigate } from "react-router-dom";

var db = firebase.firestore();

export default function Edit(props) {
  let navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const handleChange = (event) => {
    setName(event.target.value);
  };

  const update = () => {
    let obj = props.obj;

    let updateobj = {};
    updateobj[obj] = name;

    db.collection("tickets")
      .doc(props.ticketid.toString())
      .update(updateobj)
      .then(() => {
        setName("");
        setEdit(false);
        props.update();
      });
  };

  return (
    <Grid container direction="row">
      <Grid item>
        <Typography>{props.text}</Typography>
      </Grid>
      <Grid item ml={1}>
        <Grid container>
          <EditIcon
            onClick={(e) => {
              setEdit(!edit);
            }}
          />
          {edit && (
            <>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-name"
                  label={props.obj}
                  value={name}
                  onChange={handleChange}
                  size="small"
                />
              </Box>
              <DoneIcon fontSize="large" onClick={update} />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
