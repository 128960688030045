import React, { useEffect, useState } from "react";
import firebase from "./firebase";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import CommentIcon from "@mui/icons-material/Comment";
import UploadIcon from "@mui/icons-material/Upload";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import {
  Alert,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Snackbar,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

var db = firebase.firestore();

export default function AddProduct(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnacbarOpen] = useState(false);
  const [snackbarText, setsnackbarText] = useState("");
  const [snackbarAlert, setsnackbarAlert] = useState("info");
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [variant, setVariant] = useState("");
  const [service, setService] = useState("");
  const [description, setDescription] = useState("");
  const [serviceDes, setServiceDes] = useState("");
  const [servicesList, setServicesList] = useState([]);
  const [mainService, setMainService] = useState("");
  const [price, setPrice] = useState("");
  const [cost, setcost] = useState("");
  const [update, setUpdate] = useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    /*     if (!props.logged) {
      navigate("/");
    } */
    let arr = [];
    let list = [];
    setOpen(true);
    db.collection("services")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          list.push(data);
        });
      })
      .then(() => {
        setServicesList(list);
      });
    db.collection("products")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let nee = doc.data();
          arr.push(nee);
        });
      })
      .then(() => {
        let finalarr = [];
        async function loop() {
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            const vari = await getVarients(element.id);

            let obj = {};
            obj.id = element.id;
            obj.services = element.services;
            obj.variation = vari;
            finalarr.push(obj);
            if (index === arr.length - 1) {
              let stringarr = JSON.stringify(finalarr);
              db.collection("mainData")
                .doc("productsArray")
                .set({ array: stringarr })
                .then(() => {
                  setProducts(finalarr);
                  setOpen(false);
                });
            }
          }
        }
        loop();
      })
      .catch((error) => {});
  }, [update]);

  const clearState = () => {
    setProduct("");
    setVariant("");
    setService("");
    setPrice("");
    setMainService("");
    setcost("");
    setDescription("");
  };

  const getVarients = (protoget) => {
    return new Promise((resolve) => {
      let ref = db.collection("products").doc(protoget).collection("variant");
      let arr = [];
      let finalarr = [];
      ref
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            arr.push(data.id);
          });
        })
        .then(() => {
          async function loop() {
            for (let index = 0; index < arr.length; index++) {
              const element = arr[index];
              const service = await getVarientService(protoget, element);
              let obj = {};
              obj.variant = element;
              obj.services = service;
              finalarr.push(obj);
              if (index === arr.length - 1) {
                resolve(finalarr);
              }
            }
          }
          if (arr.length) {
            loop();
          } else {
            resolve([]);
          }
        });
    });
  };

  const getVarientService = (productID, vari) => {
    return new Promise((resolve) => {
      let arr = [];
      let ref = db
        .collection("products")
        .doc(productID)
        .collection("variant")
        .doc(vari)
        .collection("services");
      ref
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            arr.push(data);
          });
        })
        .then(() => {
          resolve(arr);
        });
    });
  };

  const updateProduct = () => {
    if (!props.logged) {
      navigate("/");
    }
    let productRef = db.collection("products").doc(product);
    let variationRef = productRef.collection("variant").doc(variant);
    let serviceRef = variationRef.collection("services").doc(service);
    setOpen(true);
    variationRef.get().then((x) => {
      if (x.exists) {
        let data = x.data();
        serviceRef.get().then((y) => {
          if (y.exists) {
            let serData = y.data();
            serviceRef.update({
              id: service,
              name: service,
              description: description,
              price: price,
              cost: cost,
            });
            clearState();
            setOpen(false);
            setUpdate(!update);
          } else {
            serviceRef.set({
              id: service,
              name: service,
              description: description,
              price: price,
              cost: cost,
            });
            clearState();
            setOpen(false);
            setUpdate(!update);
          }
        });
      } else {
        variationRef.set({ id: variant });
        serviceRef.set({
          id: service,
          name: service,
          price: price,
          cost: cost,
        });
        clearState();
        setOpen(false);
        setUpdate(!update);
      }
    });
  };

  const addService = () => {
    if (servicesList.includes(mainService)) {
      setsnackbarText("This service already exist");
      setsnackbarAlert("error");
      setSnacbarOpen(true);
      return;
    } else {
      db.collection("services")
        .doc(mainService)
        .set({
          name: mainService,
          description: serviceDes,
        })
        .then(() => {
          setUpdate(!update);
        });
    }
  };

  const deleteService = (ser) => {
    db.collection("services").doc(ser).delete();
    setUpdate(!update);
  };

  const deleteVariation = () => {
    let ref = db
      .collection("products")
      .doc(product)
      .collection("variant")
      .doc(variant)
      .delete()
      .then((x) => {
        setModalOpen(false);
        setSnacbarOpen(true);
        setsnackbarText("Deleted");
        setsnackbarAlert("info");
        setUpdate(!update);
        clearState();
      });
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const deleteSer = (prod, vari, ser) => {
    setOpen(true);
    db.collection("products")
      .doc(prod)
      .collection("variant")
      .doc(vari)
      .collection("services")
      .doc(ser)
      .delete()
      .then(() => {
        setOpen(false);
        setUpdate(!update);
      });
  };

  let uplpadStrip = (sname, sprice, svariant, pr) => {
    axios
      .post(
        `https://helical-arcade-322422.ew.r.appspot.com/addproduct?name=${
          svariant + " " + sname
        }&price=${sprice}`
      )
      .then((x) => {
        db.collection("products")
          .doc(pr)
          .collection("variant")
          .doc(svariant)
          .collection("services")
          .doc(sname)
          .update({
            stripProductid: x.data.productId,
            stripPriceid: x.data.priceId,
          })
          .then(() => {
            setOpen(false);
            setUpdate(!update);
          });
      });
  };

  const updateStripProduct = (sname, svariant, pr) => {
    db.collection("products")
      .doc(pr)
      .collection("variant")
      .doc(svariant)
      .collection("services")
      .doc(sname)
      .get()
      .then((x) => {
        if (x.exists) {
          let data = x.data();
          axios
            .post(
              `http://localhost:8080/updateprice?id=${data.stripPriceid}&price=${data.price}`
            )
            .then(() => {
              setOpen(false);
              setUpdate(!update);
            });
        }
      });
  };

  return (
    <Continer
      sx={{
        maxWidth: 1200,
      }}
      textAlign="center"
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        //onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </Backdrop>
      <Grid container>
        <Grid item md={6} xs={6}>
          <Grid item md={12} xs={12}>
            <InputLabel id="demo-simple-select-label">
              Select Product
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={product}
              label="Age"
              onChange={(e) => {
                setProduct(e.target.value);
              }}
            >
              {products.map((x) => (
                <MenuItem value={x.id}>{x.id}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box mr={2} marginTop={2} flexDirection="column" display="flex">
              <TextField
                label="Variant Name"
                type="text"
                variant="outlined"
                value={variant}
                onChange={(e) => {
                  setVariant(e.target.value);
                }}
              />
              <Box
                display="flex"
                justifyContent="space-evenly"
                flexDirection="row"
                alignItems="center"
              >
                <InputLabel id="demo-simple-select-label">
                  Add a Service
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service}
                  label="Age"
                  onChange={(e) => {
                    setService(e.target.value);
                  }}
                >
                  {servicesList.map((x) => (
                    <MenuItem value={x.name}>{x.name}</MenuItem>
                  ))}
                </Select>
              </Box>
              <TextField
                label="Price"
                type="number"
                variant="outlined"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
              <TextField
                label="cost"
                type="number"
                variant="outlined"
                value={cost}
                onChange={(e) => {
                  setcost(e.target.value);
                }}
              />
              <TextField
                label="Description"
                type="text"
                multiline
                variant="outlined"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  updateProduct();
                }}
                variant="contained"
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item md={6} xs={6}>
          <Grid item md={12} xs={12}>
            <Box marginTop={2} flexDirection="column" display="flex">
              <TextField
                label="Service Name"
                type="text"
                variant="outlined"
                value={mainService}
                onChange={(e) => {
                  setMainService(e.target.value);
                }}
              />
              <TextField
                label="Description"
                multiline
                type="text"
                variant="outlined"
                value={serviceDes}
                onChange={(e) => {
                  setServiceDes(e.target.value);
                }}
              />
              <Button onClick={addService} variant="contained">
                Add Service
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={7} xs={7}>
          {products &&
            products.map((x, i) => (
              <>
                <Typography variant="h6">{x.id}</Typography>
                {x.variation &&
                  x.variation.map((vari) => (
                    <Box mt={1}>
                      <Box mt={1}>
                        <Divider>{vari.variant}</Divider>
                      </Box>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                onClick={() => {
                                  setProduct(x.id);
                                  setVariant(vari.variant);
                                }}
                              >
                                {vari.variant}
                              </TableCell>
                              <TableCell>price</TableCell>
                              <TableCell>Cost</TableCell>
                              <TableCell>
                                <DeleteIcon
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    setProduct(x.id);
                                    setVariant(vari.variant);
                                    setModalOpen(true);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {vari.services &&
                              vari.services.map((ser) => (
                                <TableRow>
                                  <TableCell
                                    onClick={() => {
                                      setProduct(x.id);
                                      setVariant(vari.variant);
                                      setService(ser.id);
                                    }}
                                  >
                                    {ser.name}
                                  </TableCell>
                                  <TableCell>{ser.price}</TableCell>
                                  <TableCell>{ser.cost}</TableCell>
                                  <TableCell>
                                    <DeleteIcon
                                      onClick={() => {
                                        deleteSer(x.id, vari.variant, ser.id);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {ser.stripProductid ? (
                                      <CloudDoneIcon
                                        onClick={() => {
                                          setSnacbarOpen(true);
                                          setsnackbarText(
                                            "Produkten redan uppladat"
                                          );
                                          setsnackbarAlert("info");
                                        }}
                                      />
                                    ) : (
                                      <UploadIcon
                                        onClick={() => {
                                          uplpadStrip(
                                            ser.name,
                                            ser.price,
                                            vari.variant,
                                            x.id
                                          );
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt={1}>
                        <Divider />
                      </Box>
                    </Box>
                  ))}
              </>
            ))}
        </Grid>
        <Grid item md={5} xs={5}>
          <List>
            {servicesList.map((x) => (
              <ListItem key={x.name}>
                <ListItemIcon>
                  <Tooltip title={x.description}>
                    <CommentIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={x.name} />
                <ListItemIcon>
                  <DeleteIcon
                    onClick={() => {
                      deleteService(x.name);
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete {product} {variant}
          </Typography>

          <Box mt={2} display="flex" justifyContent="space-evenly">
            <Button color="error" variant="contained" onClick={deleteVariation}>
              Yes, sure delete
            </Button>
            <Button color="info" variant="contained">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => {
          setSnacbarOpen(false);
          setsnackbarText("");
        }}
      >
        <Alert severity={snackbarAlert} sx={{ width: "100%" }}>
          {snackbarText}
        </Alert>
      </Snackbar>
    </Continer>
  );
}

const Continer = styled(Box)({ margin: "auto" });
