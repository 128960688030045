import 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/storage'
require("firebase/firestore");


firebase.initializeApp({
    apiKey: "AIzaSyBddL-N_HjDTiHTwKoYLvqtN0uiImba2sI",
    authDomain: "helical-arcade-322422.firebaseapp.com",
    projectId: "helical-arcade-322422",
    storageBucket: "helical-arcade-322422.appspot.com",
    messagingSenderId: "360454993393",
    appId: "1:360454993393:web:13883f6089a494acd0c050",
    measurementId: "G-Z646QNPCM7"
  });


export default firebase;

/* 
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBddL-N_HjDTiHTwKoYLvqtN0uiImba2sI",
  authDomain: "helical-arcade-322422.firebaseapp.com",
  projectId: "helical-arcade-322422",
  storageBucket: "helical-arcade-322422.appspot.com",
  messagingSenderId: "360454993393",
  appId: "1:360454993393:web:13883f6089a494acd0c050",
  measurementId: "G-Z646QNPCM7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); */